import { URL } from ".";
import toast from 'react-hot-toast';

const readTeamSnapshots = async ({ token, team_id }) => {
  try {
    const response = await fetch(`${URL}/teams/${team_id}/snapshots`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read survey');
  }
}


export { readTeamSnapshots }