import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/user";
import { readSurvey } from '../api/surveys';
import Loading from '../components/Loading';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

export default function SurveyPage() {
  const user = useUser();
  const { survey_id } = useParams();
  const [survey, setSurvey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedAssignees, setExpandedAssignees] = useState({});

  useEffect(() => {
    if (user) {
      const fetchSurvey = async () => {
        setLoading(true);
        try {
          const response = await readSurvey({
            token: user.token,
            survey_id
          });

          if (response) {
            const survey = response.data;
            setSurvey(survey);
          }
        } catch (error) {
          console.error('Failed to fetch survey', error);
        } finally {
          setLoading(false);
        }
      };

      fetchSurvey();
    }
  }, [user, survey_id]);

  const calculateCompletionPercentage = () => {
    if (!survey || !survey.responses || !survey.assignees) return 0;
    const totalResponses = survey.responses.length;
    const totalAssignees = survey.assignees.length;
    return Math.round((totalResponses / totalAssignees) * 100);
  };

  const toggleAssigneeExpansion = (assigneeId) => {
    setExpandedAssignees(prev => ({
      ...prev,
      [assigneeId]: !prev[assigneeId]
    }));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold mb-4">Survey Details</h1>
        <div className="w-full border-t border-gray-600 mb-4"></div>
        {survey ? (
          <div className="space-y-6">
            <div className="space-y-2">
              <div className="flex justify-between items-center py-2">
                <span className="text-gray-200 text-xl">{survey.title}</span>
              </div>
              <div className="flex justify-between items-center py-2">
                <span className="text-gray-400">Status:</span>
                <span className="text-gray-200">{survey.status}</span>
              </div>
              <div className="flex justify-between items-center py-2">
                <span className="text-gray-400">Start Date:</span>
                <span className="text-gray-200">{moment(survey.start_date).format('MMMM D, YYYY')}</span>
              </div>
              <div className="flex justify-between items-center py-2">
                <span className="text-gray-400">Completion:</span>
                <span className="text-gray-200">{calculateCompletionPercentage()}%</span>
              </div>
            </div>

            <div className="space-y-4">
              <h2 className="text-2xl font-semibold">Questions</h2>
              {survey.questions && survey.questions.length > 0 ? (
                <ul className="list-disc list-inside space-y-2">
                  {survey.questions.map((question, index) => (
                    <li key={question._id || index} className="text-gray-200">
                      {question.text}
                      <span className="text-gray-400 ml-2">({question.type})</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-400">No questions available for this survey.</p>
              )}
            </div>

            <div className="space-y-4">
              <h2 className="text-2xl font-semibold">Assignees and Responses</h2>
              {survey.assignees && survey.assignees.length > 0 ? (
                <ul className="space-y-2">
                  {survey.assignees.map((assignee) => {
                    const response = survey?.responses?.find(r => r.respondent._id === assignee._id);
                    const isExpanded = expandedAssignees[assignee._id];
                    return (
                      <li key={assignee._id} className="bg-gray-800 p-4 rounded-lg">
                        <div
                          className="flex justify-between items-center cursor-pointer"
                          onClick={() => toggleAssigneeExpansion(assignee._id)}
                        >
                          <span className="text-gray-200">{assignee.name}</span>
                          <div className="flex items-center">
                            <span className={`text-sm mr-2 ${response ? 'text-green-500' : 'text-red-500'}`}>
                              {response ? 'Completed' : 'Not Completed'}
                            </span>
                            {isExpanded ? (
                              <ChevronUpIcon className="h-5 w-5 text-gray-400" />
                            ) : (
                              <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                            )}
                          </div>
                        </div>
                        {isExpanded && response && (
                          <div className="mt-4 pl-4 border-l-2 border-gray-600">
                            <ul className="space-y-2">
                              {response.answers.map((answer, index) => (
                                <li key={index} className="text-gray-300">
                                  <span className="font-semibold">{answer.question.text}: </span>
                                  {answer.answer}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className="text-gray-400">No assignees for this survey.</p>
              )}
            </div>
          </div>
        ) : (
          <p className="text-center">No survey found.</p>
        )}
      </div>
    </div>
  );
}