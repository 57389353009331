import React, { useEffect, useState } from "react"
import { useUser } from "../contexts/user";
import { readOrgTeams, readOrg, readOrgCommits } from '../api/organizations';
import { deleteTeam } from '../api/teams';
import { readCommit } from '../api/commits';
import { updateUser } from '../api/users';
import Loading from '../components/Loading';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { PlusIcon, PencilIcon, XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import TeamForm from '../components/TeamForm';
import moment from 'moment';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


const Dropdown = ({ title, children, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="mb-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full px-4 py-2 text-lg font-medium text-left text-white bg-gray-600 rounded-lg hover:bg-gray-500 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
      >
        <span>{title}</span>
        <ChevronDownIcon
          className={`${isOpen ? 'transform rotate-180' : ''} w-5 h-5 text-white`}
        />
      </button>
      {isOpen && (
        <div className="mt-2 p-4 bg-gray-800 rounded-lg">
          {children}
        </div>
      )}
    </div>
  );
};

export default function AdminPage() {
  const user = useUser();
  const navigate = useNavigate();
  const { org_id } = useParams()
  const [teams, setTeams] = useState([]);
  const [org, setOrg] = useState(null);
  const [commits, setCommits] = useState([]);
  const [failedSaves, setFailedSaves] = useState([]);
  const [commitsLoading, setCommitsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTeamForm, setShowTeamForm] = useState(false);
  const [editingTeam, setEditingTeam] = useState(null);
  const [commitsByDate, setCommitsByDate] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [updates, setUpdates] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(6, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));


  const processCommitData = (commits) => {
    const commitsByDate = commits.reduce((acc, commit) => {
      const date = moment(commit.created_at).format('YYYY-MM-DD');
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const chartData = Object.entries(commitsByDate)
      .sort(([dateA], [dateB]) => moment(dateA).diff(moment(dateB)))
      .map(([date, count]) => ({
        label_date: moment(date).format('MM/DD'),
        commits: count
      }));

    setCommitsByDate(chartData);
  }

  const handleUserSelection = ({ commit, user_id }) => {
    setUpdates(prevUpdates => {
      const newUpdates = new Map(prevUpdates);
      if (newUpdates.has(user_id)) {
        newUpdates.get(user_id).push(commit);
      } else {
        newUpdates.set(user_id, [commit]);
      }
      return newUpdates;
    });

  };

  const handleTeamSaved = (savedTeam) => {
    if (editingTeam) {
      setTeams(teams.map(team => team._id === savedTeam._id ? savedTeam : team));
    } else {
      setTeams([...teams, savedTeam]);
    }
    setShowTeamForm(false);
    setEditingTeam(null);
  }

  const handleEditTeam = (team) => {
    setEditingTeam(team);
    setShowTeamForm(true);
  }

  const saveUserNames = async () => {
    setLoading(true);

    try {
      const updatedSaves = Array.from(updates).map(([userId, commits]) => ({
        userId,
        userNames: commits.map(commit => commit.author_email)
      }));

      const results = await Promise.all(updatedSaves.map(async (save) => {
        try {
          const response = await updateUser({
            token: user.token,
            user_id: save.userId,
            updates: {
              gitlab_usernames: save.userNames
            }
          });
          if (!response.ok) {
            throw new Error('Failed to update failed saves');
          }
          const result = await response.json();
          return result;
        } catch (error) {
          console.error('Failed to update user', error);
          return null;
        }
      }));

      console.log(results);
    } catch (error) {
      console.error('Failed to save failed saves', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTeam = async (team_id) => {
    const confirmed = window.confirm("Are you sure you want to delete this team?")
    if (!confirmed) return;

    const previousTeams = [...teams];
    setTeams(teams.filter(team => team._id !== team_id));

    try {
      const response = await deleteTeam({
        token: user.token,
        team_id,
      });
      if (response.ok) {
        const teams = await readOrgTeams({
          token: user.token,
          org_id: user.organization,
        })
        setTeams([...teams.data]);
      }
    } catch (error) {
      setTeams(previousTeams);
      console.error('Failed to delete team', error);
    }
    finally {
      setLoading(false)
    }
    console.log(`Successfully deleted team`);
  }

  const fetchCommit = async (commit_id) => {
    const { data } = await readCommit({
      token: user.token,
      commit_id,
    });
    console.log({ data })
    return data;
  }

  const fetchCommits = async ({ failedSaves }) => {
    const commits = await Promise.all(failedSaves.map(failedSave => fetchCommit(failedSave.commit)));
    console.log({ commits })
    return commits;
  }

  const getUniqueFailedSaves = async ({ failedSaves, users }) => {
    const uniqueEmails = new Set([
      ...users.flatMap(user => user.gitlab_usernames || []),
    ]);

    const commits = await fetchCommits({ failedSaves });

    const res = commits.filter(commit => {
      if (uniqueEmails.has(commit.author_email)) {
        return false;
      }
      uniqueEmails.add(commit.author_email);
      return true;
    });

    console.log({ res })

    return res;
  };

  useEffect(() => {
    if (user) {
      const fetchOrgCommits = async () => {
        setCommitsLoading(true)
        try {
          const { data } = await readOrgCommits({
            token: user.token,
            org_id: user.organization,
            start_date: startDate,
            end_date: endDate,
          });
          if (!!data) {
            setCommits(data);
            processCommitData(data);
          }
        }
        catch (error) {
          console.error('Failed to fetch org commits', error);
        }
        finally {
          setCommitsLoading(false)
        }
      };

      fetchOrgCommits();
    }
  }, [user, startDate, endDate]);


  useEffect(() => {
    if (user) {
      const fetchTeams = async () => {
        setLoading(true)
        try {
          const response = await readOrgTeams({
            token: user.token,
            org_id: user.organizationr
          });
          if (response) {
            setTeams([...response.data]);
          }
        } catch (error) {
          console.error('Failed to fetch teams', error);
        }
        finally {
          setLoading(false)
        }
      };

      const fetchOrg = async () => {
        setLoading(true)
        try {
          const response = await readOrg({
            token: user.token,
            org_id: user.organization,
            include: 'failed_saves, commits',
          });
          if (response) {
            setOrg({ ...response.data });
            setFailedSaves(await getUniqueFailedSaves({ failedSaves: response.data.failed_saves || [], users: response.data.members || [] }));
          }
        } catch (error) {
          console.error('Failed to fetch org', error);
        }
        finally {
          setLoading(false)
        }
      };

      // fetchTeams();
      fetchOrg();
    }
  }, [user]);

  if (loading) {
    return <Loading />
  }

  if (showTeamForm) {
    return <TeamForm
      initialTeam={editingTeam}
      onTeamSaved={handleTeamSaved}
      onCancel={() => {
        setShowTeamForm(false);
        setEditingTeam(null);
      }}
    />;
  }

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-4xl mx-auto">

        <Dropdown title="Surveys">
          <div className="space-y-2">
            <p className="text-center">No surveys found.</p>
          </div>
          <div className="mt-4 flex justify-end">
            <button
              onClick={() => navigate(`/org/${org_id}/surveys`)}
              className="text-white hover:rotate-90 transition-transform duration-300 focus:outline-none"
              aria-label="Create new survey"
            >
              <PlusIcon className='h-6 w-6' />
            </button>
          </div>
        </Dropdown>

        <Dropdown title={`Commits: (${commits.length})`} defaultOpen={true}>
          <div className="space-y-4">
            <div className="flex items-center gap-4 mb-4">
              <div className="flex items-center gap-2">
                <label htmlFor="startDate">Start Date:</label>
                <input
                  type="date"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="bg-gray-800 text-white rounded px-2 py-1"
                />
              </div>

              <div className="flex items-center gap-2">
                <label htmlFor="endDate">End Date:</label>
                <input
                  type="date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="bg-gray-800 text-white rounded px-2 py-1"
                />
              </div>
            </div>
            <div className="w-full ">
              {commitsByDate.length > 0 ? (
                <div className="w-full flex items-center justify-center">
                  <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={commitsByDate}>
                      <XAxis dataKey="label_date" />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="commits" stroke="#8884d8" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <p>No commits found 😭</p>
              )}
            </div>
          </div>
        </Dropdown>

        <Dropdown title={`Unknown Users: (${failedSaves.length})`}>
          <div className="space-y-2 overflow-x-auto">
            {failedSaves.length > 0 ? (
              <table className="w-full text-left text-sm">
                <thead>
                  <tr className="bg-gray-700">
                    <th className="px-2 py-1">Error</th>
                    <th className="px-2 py-1">Email</th>
                    <th className="px-2 py-1">Attribute</th>
                    <th className="px-2 py-1">Member Name</th>
                  </tr>
                </thead>
                <tbody>
                  {failedSaves.length > 0 && failedSaves.map(failedSave => (
                    <tr key={failedSave.commit_id} className="border-b border-gray-600">
                      <td className="px-2 py-1 text-gray-200 text-sm">{failedSave.message.substring(0, 25)}...</td>
                      <td className="px-2 py-1 text-gray-400">{failedSave.author_email}</td>
                      <td className="px-2 py-1">
                        <select
                          className="bg-gray-700 text-white rounded px-1 py-0.5 text-sm"
                          value={selectedUsers[failedSave.commit_id] || ''}
                          onChange={(e) => handleUserSelection({ commit: failedSave, user_id: e.target.value })}
                        >
                          <option value="">Attribute</option>
                          {org?.members?.sort((a, b) => {
                            const nameA = (a.name || a.email)?.charAt(0).toLowerCase();
                            const nameB = (b.name || b.email)?.charAt(0).toLowerCase();
                            return nameA?.localeCompare(nameB);
                          }).map(member => (
                            <option key={member._id} value={member._id}>
                              {member.name || member.email}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="px-2 py-1">
                        {Array.from(updates.entries()).map(([userId, commits]) => {
                          if (commits.some(commit => commit._id === failedSave._id)) {
                            const user = org?.members?.find(member => member._id === userId);
                            return user ? user.name || user.email : 'Unknown User';
                          }
                          return null;
                        }).filter(Boolean).join(', ')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-center">No failed saves found.</p>
            )}
          </div>
          {failedSaves.length > 0 && (
            <div className="mt-4 flex justify-end">
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={saveUserNames}
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save Selected'}
              </button>
            </div>
          )}
        </Dropdown>

      </div >
    </div >
  )
}
