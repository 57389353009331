import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/user";
import { useParams, Link } from 'react-router-dom';
import { readUser, readUserSurveys } from '../api/users';
import Loading from '../components/Loading';
import moment from 'moment';
import { PencilIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../components/catalyst/description-list'

export default function ProfilePage() {
  const user = useUser();
  const { user_id, org_id } = useParams();
  const [profile, setProfile] = useState(null);
  const [commits, setCommits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [surveysLoading, setSurveysLoading] = useState(true);
  const [userSurveys, setUserSurveys] = useState([]);
  const [error, setError] = useState(null);
  const [showAllCommits, setShowAllCommits] = useState(false);
  const [showSurveys, setShowSurveys] = useState(false);

  const user_is_admin = user.organization_role !== 'member';

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await readUser({
          token: user.token,
          user_id,
          include: 'surveys,commits',
          start_date: moment().subtract(16, 'days').toISOString(),
          end_date: moment().toISOString()
        });
        if (response && response.data) {
          setProfile({ ...response.data });
          setCommits(response.data.commits || []);
          setUserSurveys(response.data.surveys || []);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        setError('Failed to load user data');
      } finally {
        setLoading(false);
      }
    };


    if (user && user_id) {
      fetchUser();
    }
  }, [user, user_id]);

  if (loading) return <Loading />;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!profile) return <div className="text-white">No profile found</div>;

  // Calculate the rolling 7-day commit count
  const commitsByDay = commits
    .reduce((acc, commit) => {
      const date = moment(commit.created_at).toISOString().slice(0, 10);
      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date]++;
      return acc;
    }, {});

  const commitData = Object.keys(commitsByDay)
    .map(date => ({
      date,
      label_date: moment(date).format('MM/DD'),
      commits: commitsByDay[date]
    }))
    .slice(-7)
    .sort((a, b) => moment(a.date).isAfter(moment(b.date))); // Sort by date in ascending order

  const sortedCommits = [...commits].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between align-center">
          <h1 className="text-2xl mb-2 font-bold ">{profile.name}</h1>
          {user_is_admin && (
            <Link
              className="text-white hover:rotate-90 transition-transform duration-300 focus:outline-none"
              aria-label="Edit profile"
              to={`update`}
            >
              <PencilIcon className='h-4 w-4' />
            </Link>
          )}
        </div>
        <div className="w-full border-t border-gray-600 mb-4"></div>
        <div className="mb-4">
          <DescriptionList>
            <DescriptionTerm>Email</DescriptionTerm>
            <DescriptionDetails className="text-right">{profile.email}</DescriptionDetails>
            <DescriptionTerm>Role</DescriptionTerm>
            <DescriptionDetails className="text-right">{profile.organization_role}</DescriptionDetails>
            <DescriptionTerm>Title</DescriptionTerm>
            <DescriptionDetails className="text-right">{profile.title}</DescriptionDetails>
            <DescriptionTerm>Gitlab Usernames</DescriptionTerm>
            <DescriptionDetails className="text-right">{profile.gitlab_usernames?.join(', ') || ''}</DescriptionDetails>
          </DescriptionList>
        </div>
        {(user_is_admin || profile._id == user.id)
          ? <>
            <div className="mt-8 ">
              <h2 className="text-xl font-bold mb-2">Commit Frequency</h2>
              <div className="w-full border-t border-gray-600 mb-4"></div>
              {commitData.length ? (
                <div className="w-full flex items-center justify-center">
                  <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={commitData}>
                      <XAxis dataKey="label_date" />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="commits" stroke="#8884d8" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <p>No commits found 😭</p>
              )}
            </div>

            <div className="mt-8">
              <div
                className="flex justify-between items-center cursor-pointer  p-4 rounded-t-lg"
                onClick={() => setShowAllCommits(!showAllCommits)}
              >
                <h2 className="text-xl font-bold">Commit Details</h2>

                {showAllCommits ? (
                  <ChevronUpIcon className="h-6 w-6" />
                ) : (
                  <ChevronDownIcon className="h-6 w-6" />
                )}
              </div>
              <div className="w-full border-t border-gray-600 mb-4"></div>

              {showAllCommits && (
                <div className="bg-gray-800 p-4 rounded-b-lg overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-700">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Date</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Message</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Repository</th>
                      </tr>
                    </thead>
                    <tbody className="bg-gray-800 divide-y divide-gray-700">
                      {sortedCommits.map((commit, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                            {moment(commit.created_at).format('MM/DD/YYYY')}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-300">
                            {commit.commit_message}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                            {commit.commit_project_name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="mt-8">
              <div
                className="flex justify-between items-center cursor-pointer p-4 rounded-t-lg"
                onClick={() => setShowSurveys(!showSurveys)}
              >
                <h2 className="text-xl font-bold">Surveys</h2>
                {showSurveys ? (
                  <ChevronUpIcon className="h-6 w-6" />
                ) : (
                  <ChevronDownIcon className="h-6 w-6" />
                )}
              </div>
              <div className="w-full border-t border-gray-600 mb-4"></div>
              {showSurveys && (
                <div className="bg-gray-800 p-4 rounded-b-lg">
                  {userSurveys.map((survey, index) => (
                    <div key={index}>
                      <Link to={`/org/${org_id}/profile/${user_id}/surveys/${survey._id}/complete`}>{survey.title}</Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
          : <></>}
      </div>
    </div>
  );
}