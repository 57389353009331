import React, { useEffect, useState } from "react"
import { useUser } from "../contexts/user";
import { readOrgMembers } from '../api/organizations';
import Loading from '../components/Loading';
import { Link, useNavigate } from 'react-router-dom';
import { PlusIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';

export default function MembersPage() {
  const user = useUser();
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const user_is_admin = user.organization_role !== 'member';

  useEffect(() => {
    if (user) {
      const fetchMembers = async () => {
        setLoading(true)
        try {
          const response = await readOrgMembers({
            token: user.token,
            org_id: user.organization,
          });
          if (response) {
            setMembers([...response.data].sort((a, b) => a.name.localeCompare(b.name)));
          }
        } catch (error) {
          console.error('Failed to fetch members', error);
        }
        finally {
          setLoading(false)
        }
      };

      fetchMembers();
    }
  }, [user]);

  const handleEditMember = (member) => {
    navigate(`/org/${user.organization}/members/${member._id}/edit`);
  };

  const handleDeleteMember = async (memberId) => {
    // Implement delete functionality here
    console.log(`Delete member with ID: ${memberId}`);
  };

  if (loading) {
    return <Loading />
  }

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Members ({members?.length || ''})</h1>
          {user_is_admin && (
            <button
              onClick={() => navigate(`/org/${user.organization}/members/new`)}
              className="text-white hover:rotate-90 transition-transform duration-300 focus:outline-none"
              aria-label="Create new member"
            >
              <PlusIcon className='h-6 w-6' />
            </button>
          )}
        </div>
        <div className="w-full border-t border-gray-600 mb-4"></div>
        <div className="space-y-2">
          {members?.length > 0 ? (
            members.map(member => (
              <div key={member._id} className="flex justify-between items-center py-2">
                <Link to={`/org/${user.organization}/profile/${member._id}`} className="text-gray-200 hover:text-gray-300">
                  {member.name}
                </Link>
                {user_is_admin && (
                  <div>
                    <button
                      onClick={() => handleEditMember(member)}
                      className="text-gray-200 hover:text-gray-300 mr-3 transition-transform duration-200 ease-in-out hover:rotate-12"
                      aria-label={`Edit ${member.name}`}
                    >
                      <PencilIcon className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => handleDeleteMember(member._id)}
                      className="text-red-500 hover:text-red-400 transition-transform duration-200 ease-in-out hover:rotate-90"
                      aria-label={`Delete ${member.name}`}
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="text-center">No members found.</p>
          )}
        </div>
      </div>
    </div>
  )
}