import React, { useEffect, useState } from "react"
import { useUser } from "../contexts/user";
import { readOrgTeams } from '../api/organizations';
import { deleteTeam } from '../api/teams';
import Loading from '../components/Loading';
import { Link, useParams } from 'react-router-dom';
import { PlusIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import TeamForm from '../components/TeamForm';

export default function TeamsPage() {
  const user = useUser();
  const { org_id } = useParams();
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTeamForm, setShowTeamForm] = useState(false);
  const [editingTeam, setEditingTeam] = useState(null);

  const createTeamClicked = () => {
    setEditingTeam(null);
    setShowTeamForm(true);
  }

  const handleTeamSaved = (savedTeam) => {
    if (editingTeam) {
      setTeams(teams.map(team => team._id === savedTeam._id ? savedTeam : team));
    } else {
      setTeams([...teams, savedTeam]);
    }
    setShowTeamForm(false);
    setEditingTeam(null);
  }

  const handleEditTeam = (team) => {
    setEditingTeam(team);
    setShowTeamForm(true);
  }

  const handleDeleteTeam = async (team_id) => {
    const confirmed = window.confirm("Are you sure you want to delete this team?")
    if (!confirmed) return;

    const previousTeams = [...teams];
    setTeams(teams.filter(team => team._id !== team_id));

    try {
      const response = await deleteTeam({
        token: user.token,
        team_id,
      });
      if (response.ok) {
        const teams = await readOrgTeams({
          token: user.token,
          org_id: user.organization,
        })
        setTeams([...teams.data]);
      }
    } catch (error) {
      setTeams(previousTeams);
      console.error('Failed to delete team', error);
    }
    finally {
      console.log(teams)
      setLoading(false)
    }
    console.log(`Successfully deleted team`);
  }

  useEffect(() => {
    if (user) {
      const fetchTeams = async () => {
        setLoading(true)
        try {
          const response = await readOrgTeams({
            token: user.token,
            org_id: user.organization,
          });
          if (response) {
            setTeams([...response.data]);
          }
        } catch (error) {
          console.error('Failed to fetch teams', error);
        }
        finally {
          setLoading(false)
        }
      };

      fetchTeams();
    }
  }, [user]);

  if (loading) {
    return <Loading />
  }

  if (showTeamForm) {
    return <TeamForm
      initialTeam={editingTeam}
      onTeamSaved={handleTeamSaved}
      onCancel={() => {
        setShowTeamForm(false);
        setEditingTeam(null);
      }}
    />;
  }

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-4">Teams</h1>
        <div className="w-full border-t border-gray-600 mb-4"></div>
        <div className="space-y-2">
          {teams?.length > 0 ? (
            teams.map(team => (
              <div key={team._id} className="flex justify-between items-center py-2">
                <Link to={`/org/${org_id}/teams/${team._id}`} className="text-gray-200 hover:text-gray-300">
                  {team.name}
                </Link>
                <div>
                  <button
                    onClick={() => handleEditTeam(team)}
                    className="text-gray-200 hover:text-gray-300 mr-3 transition-transform duration-200 ease-in-out hover:rotate-12"
                    aria-label={`Edit ${team.name}`}
                  >
                    <PencilIcon className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => handleDeleteTeam(team._id)}
                    className="text-red-500 hover:text-red-400 transition-transform duration-200 ease-in-out hover:rotate-90"
                    aria-label={`Delete ${team.name}`}
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center">No teams found.</p>
          )}
        </div>
        <div className="mt-4 flex justify-end">
          <button
            onClick={createTeamClicked}
            className="text-white hover:rotate-90 transition-transform duration-300 focus:outline-none"
            aria-label="Create new team"
          >
            <PlusIcon className='h-6 w-6' />
          </button>
        </div>
      </div>
    </div>
  )
}