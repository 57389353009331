import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/user";
import { useParams, Link } from 'react-router-dom';
import { readTeam, updateTeam } from '../api/teams';
import { readTeamSnapshots } from '../api/snapshots';
import { readTeamCommits } from '../api/commits';
import Loading from '../components/Loading';
import AddMemberToTeam from '../components/AddMemberToTeam';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import moment from 'moment';

const taskTypeColors = {
  'Background': '#F5F5F5',
  'Enhancement': '#1E88E5',
  'Tech Debt': '#FF7043',
  'Bug': '#8E24AA',
  'Unspecified': '#00897B',
  'Security': '#D32F2F',
  'Automation': '#43A047',
  'New Feature': '#FFC107'
};

const processCommitData = (commits) => {
  const commitsByDate = {};
  const users = new Set();

  commits.forEach(commit => {
    const date = new Date(commit.created_at).toLocaleDateString();
    users.add(commit.author_name);
    if (!commitsByDate[date]) {
      commitsByDate[date] = {};
    }
    if (!commitsByDate[date][commit.author_name]) {
      commitsByDate[date][commit.author_name] = 0;
    }
    commitsByDate[date][commit.author_name]++;
  });

  const chartData = Object.keys(commitsByDate).map(date => ({
    date,
    ...commitsByDate[date]
  }));

  return { chartData, users: Array.from(users) };
};

const CommitChart = ({ data, users }) => {
  const [highlightedUser, setHighlightedUser] = useState(null);
  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#a4de6c', '#d0ed57', '#83a6ed'];

  const handleMouseEnter = (o) => {
    setHighlightedUser(o.dataKey);
  };

  const handleMouseLeave = () => {
    setHighlightedUser(null);
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        {users.map((user, index) => (
          <Line
            key={user}
            type="monotone"
            dataKey={user}
            stroke={colors[index % colors.length]}
            strokeWidth={highlightedUser === user ? 3 : 1}
            dot={false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default function TeamPage() {
  const user = useUser();
  const { org_id, team_id } = useParams();
  const [team, setTeam] = useState(null);
  const [snapshots, setTeamSnapshots] = useState(null);
  const [selectedSnapshot, setSelectedSnapshot] = useState(null);
  const [selectedSnapshotData, setSelectedSnapshotData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snapshotsLoading, setSnapshotsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAddMember, setShowAddMember] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [membersOpen, setMembersOpen] = useState(false);
  const [snapshotsOpen, setSnapshotsOpen] = useState(false);
  const [commitChartData, setCommitChartData] = useState([]);
  const [commitUsers, setCommitUsers] = useState([]);
  const [commitChartOpen, setCommitChartOpen] = useState(false);
  const [commitRange, setCommitRange] = useState('7d');
  const [commitStart, setCommitStart] = useState(moment().subtract(7, 'days').toDate());
  const [commitEnd, setCommitEnd] = useState(moment().toDate());

  const processSnapshotData = (snapshots) => {
    const processedData = snapshots.map(snapshot => {
      const dataPoint = {
        created_at: new Date(snapshot.created_at).toLocaleDateString(),
      };
      snapshot.task_counts.forEach(task => {
        dataPoint[task.task_type] = task.points;
      });
      return dataPoint;
    });
    setChartData(processedData);
  };

  const fetchTeamCommits = async (start, end) => {
    try {
      const response = await readTeamCommits({
        token: user.token,
        team_id,
        start,
        end
      });
      if (response && response.data) {
        const { chartData, users } = processCommitData(response.data);
        setCommitChartData(chartData);
        setCommitUsers(users);
      }
    } catch (error) {
      console.error('Error fetching team commits:', error);
    }
  };

  const handleRangeChange = (range) => {
    let start;
    switch (range) {
      case '7d':
        start = moment().subtract(7, 'days');
        break;
      case '30d':
        start = moment().subtract(30, 'days');
        break;
      case '60d':
        start = moment().subtract(60, 'days');
        break;
      default:
        start = moment().subtract(7, 'days');
    }
    const end = moment();
    setCommitRange(range);
    setCommitStart(start.toDate());
    setCommitEnd(end.toDate());
    fetchTeamCommits(start.toDate(), end.toDate());
  };

  useEffect(() => {
    const fetchTeamSnapshots = async () => {
      setSnapshotsLoading(true);
      try {
        const response = await readTeamSnapshots({
          token: user.token,
          team_id,
        });
        if (response) {
          setTeamSnapshots([...response.data]);
          processSnapshotData([...response.data]);
        }
      } catch (error) {
        console.error('Error fetching team snapshots:', error);
      } finally {
        setSnapshotsLoading(false);
      }
    };

    const fetchTeamCommits = async () => {
      try {
        const response = await readTeamCommits({
          token: user.token,
          team_id,
          start: commitStart,
          end: commitEnd
        });
        if (response && response.data) {
          const { chartData, users } = processCommitData(response.data);
          setCommitChartData(chartData);
          setCommitUsers(users);
        }
      } catch (error) {
        console.error('Error fetching team commits');
      }
    };

    const fetchTeam = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await readTeam({
          token: user.token,
          team_id,
        });
        if (response) {
          setTeam({ ...response.data });
        }
      } catch (error) {
        console.error('Error fetching team:', error);
        setError('Failed to load team data');
      } finally {
        setLoading(false);
      }
    };

    if (user && team_id) {
      fetchTeam();
      fetchTeamSnapshots();
      fetchTeamCommits();
    }
  }, [user, team_id, commitStart, commitEnd]);

  const handleAddMemberSubmit = async (memberIds) => {
    try {
      await updateTeam({
        token: user.token,
        team_id: team._id,
        updates: { members: memberIds }
      })
      setShowAddMember(false);
    } catch (error) {
      console.error('Error adding team member:', error);
      setError('Failed to add team member');
    }
  };

  const toggleMembers = () => setMembersOpen(!membersOpen);
  const toggleSnapshots = () => setSnapshotsOpen(!snapshotsOpen);

  if (loading) return <Loading />;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!team) return <div className="text-white">No team found</div>;

  if (showAddMember) {
    return <AddMemberToTeam
      team={team}
      onSubmit={handleAddMemberSubmit}
      onCancel={() => setShowAddMember(false)}
    />;
  }

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-4">{team.name}</h1>
        <div className="w-full border-t border-gray-600 mb-4"></div>

        {/* Team Members Dropdown */}
        <div className="mb-4">
          <button
            onClick={toggleMembers}
            className="flex items-center justify-between w-full py-2 px-4 bg-gray-600 hover:bg-gray-500 rounded"
          >
            <span className="text-xl font-semibold">Team Members</span>
            {membersOpen ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
          </button>
          {membersOpen && (
            <div className="mt-2 space-y-2 pl-4">
              {team.members && team.members.length > 0 ? (
                team.members.map(member => (
                  <div key={member._id} className="flex justify-between items-center py-2">
                    <Link to={`/org/${org_id}/profile/${member._id}`} className="text-gray-200 hover:underline cursor">
                      {member.name}
                    </Link>
                  </div>
                ))
              ) : (
                <p className="text-center">No team members found. Add Some!</p>
              )}
            </div>
          )}
        </div>

        {/* Backlog Snapshots Dropdown */}
        <div className="mb-4">
          <button
            onClick={toggleSnapshots}
            className="flex items-center justify-between w-full py-2 px-4 bg-gray-600 hover:bg-gray-500 rounded"
          >
            <span className="text-xl font-semibold">Backlog Snapshots</span>
            {snapshotsOpen ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
          </button>
          {snapshotsOpen && (
            <div className="mt-2">
              {chartData.length > 0 ? (
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <XAxis dataKey="created_at" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 6" />
                    <Tooltip />
                    <Legend />
                    {Object.keys(chartData[0]).filter(key => key !== 'created_at').map((taskType) => (
                      <Line
                        key={taskType}
                        type="monotone"
                        dataKey={taskType}
                        stroke={taskTypeColors[taskType]}
                        name={<span style={{ fontSize: '0.6em', display: 'flex', justifyContent: 'center' }}>{taskType}</span>}
                        dot={false}
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p className="text-center">No snapshots found</p>
              )}
            </div>
          )}
        </div>

        {/* Commit Chart */}
        <div className="mb-4">
          <button
            onClick={() => setCommitChartOpen(!commitChartOpen)}
            className="flex items-center justify-between w-full py-2 px-4 bg-gray-600 hover:bg-gray-500 rounded"
          >
            <span className="text-xl font-semibold">Commit Activity</span>
            {commitChartOpen ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
          </button>
          {commitChartOpen && (
            <div className="mt-2">
              <div className="flex justify-center mb-4">
                <div className="inline-flex rounded-md shadow-sm" role="group">
                  {['7d', '30d', '60d'].map((range) => (
                    <button
                      key={range}
                      type="button"
                      onClick={() => handleRangeChange(range)}
                      className={`px-4 py-2 text-sm font-medium ${commitRange === range
                        ? 'bg-blue-600 text-white'
                        : 'bg-white text-gray-700 hover:bg-gray-50'
                        } ${range === '7d' ? 'rounded-l-lg' : range === '60d' ? 'rounded-r-lg' : ''
                        } border border-gray-200`}
                    >
                      {range}
                    </button>
                  ))}
                </div>
              </div>
              {commitChartData.length > 0 ? (
                <CommitChart data={commitChartData} users={commitUsers} />
              ) : (
                <p className="text-center">No commit data available</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}