import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/user";
import { readSurvey, saveSurveyResponses } from '../api/surveys';
import { readUserTeam } from '../api/users';
import Loading from '../components/Loading';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';
import toast from "react-hot-toast";

// todo

// current survey fetch gets clean version..loading partially submitted version?
// submit sets survey to completed
// if survey is completed, show thank you page
// subject_id for peer questions,
// question type, save these on response 

export default function SurveyCompletionPage() {
  const user = useUser();
  const navigate = useNavigate();
  const { user_id, org_id, survey_id } = useParams();
  const [survey, setSurvey] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [teamLoading, setTeamLoading] = useState(false);

  useEffect(() => {
    if (user) {
      const fetchSurveyAndTeam = async () => {
        setLoading(true);
        try {
          const teamResponse = await readUserTeam({
            token: user.token,
            user_id
          });


          const surveyResponse = await readSurvey({
            token: user.token,
            survey_id
          });

          if (surveyResponse && teamResponse) {
            setTeam(teamResponse.data);
            setSurvey(surveyResponse.data);
            const formattedQuestions = formatQuestions(surveyResponse.data, teamResponse.data)
            console.log('formattedQuestions', formattedQuestions)
            setQuestions([...formattedQuestions])
          }
        } catch (error) {
          console.error('Failed to fetch data', error);
        } finally {
          setLoading(false);
        }
      };

      fetchSurveyAndTeam();
    }
  }, [user, survey_id]);


  const formatSubject = ({ questions, team }) => {
    return questions?.map((question) => {
      if (question.subject === 'self') {
        return {
          id: uuidv4(),
          question: question._id,
          text: question.text,
          answer: '',
          author_id: user_id,
          subject_id: null,
          type: question.type,
        }
      }
      else if (question.subject === 'peer') {
        let team_qs = team.map(member => {
          return {
            id: uuidv4(),
            question: question._id,
            text: question.text + ` ${member.name}`,
            answer: '',
            author_id: user_id,
            subject_id: member._id,
            type: question.type,
          }
        })
        return team_qs
      }
    }).flat();

  }

  const formatOptions = ({ questions }) => {
    return questions?.map((question) => {
      if (question.type === 'text') {
        return {
          ...question,
          options: null,
        }
      }
      else if (question.type === 'likert') {
        return {
          ...question,
          options: [
            'Strongly Disagree',
            'Disagree',
            'Neutral',
            'Agree',
            'Strongly Agree',
          ]
        }
      }
    })
  }

  const formatQuestions = (survey, team) => {
    const formattedSubject = formatSubject({ questions: survey.questions, team })
    const formattedOptions = formatOptions({ questions: [...formattedSubject] })
    return [...formattedOptions]
  }

  const saveResponses = async ({ complete = false }) => {
    console.log('saving responses')

    let responses = questions.map(q => ({
      question: q.question,
      text: q.text,
      answer: q.answer,
      survey: survey_id,
      author_id: q.author_id,
      subject_id: q.subject_id,
      type: q.type,
    }))


    // build responses here, then save them
    try {
      const response = await saveSurveyResponses({
        token: user.token,
        org_id,
        user_id,
        survey_id,
        responses,
        complete
      });
      toast.success(response.message)
      navigate(`/org/${org_id}/profile/${user_id}`)
    } catch (error) {
      toast.error('Failed to save responses')
      console.error('Failed to save responses');
    };
  }

  // Handle input change
  const handleInputChange = (index, id, value) => {
    const updatedQuestions = [
      ...questions.map(q => q.id === id ? { ...q, answer: value } : q)];
    setQuestions([...updatedQuestions]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const response = await saveResponses({ complete: true }); // Save responses when the form is submitted
    console.log('Form submitted:', questions);
    // Optionally, you can redirect or show a success message here
  };

  useEffect(() => {
    // Save responses every 5 seconds
    // const intervalId = setInterval(() => {
    //   saveResponses();
    // }, 5000);

    // // Cleanup interval on component unmount
    // return () => clearInterval(intervalId);
  }, [questions]); // Dependency array includes responses

  if (loading) {
    return <Loading />;
  }

  if (!survey) {
    return <p className="text-center">No survey found.</p>;
  }

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold mb-4">{survey.title}</h1>
        <div className="w-full border-t border-gray-600 mb-4"></div>
        <div className="space-y-6">
          <div className="space-y-4">
            <h2 className="text-2xl font-semibold">Questions:</h2>
            {!!questions && questions?.length > 0 ? (
              <form>
                <ul className="list-numbered list-inside space-y-24">
                  {questions?.map((question, index) => (
                    <li key={question.id || index} className="text-white bg-gray-900 p-4 rounded-lg">
                      <label className="mb-2">{index + 1}: {question.text}</label>
                      {question.type === 'text' && (
                        <textarea
                          className="w-full p-2 border text-white bg-gray-600 rounded resize-none" // Added resize-none to prevent resizing
                          value={questions.find(q => q.id === question.id).answer}
                          onChange={(e) => handleInputChange(index, question.id, e.target.value)}
                          rows="4" // Set the number of visible rows
                          placeholder="Type your response here..." // Optional placeholder
                        />
                      )}
                      {question.type === 'likert' && (
                        <div className="flex flex-col items-center">
                          <input
                            type="range"
                            min="1"
                            max="5"
                            step="1"
                            value={questions.find(q => q.id === question.id).answer || 3}
                            onChange={(e) => handleInputChange(index, question.id, e.target.value)}
                            className="slider w-[90%]"
                          />
                          <div className="flex justify-between text-gray-200 mt-2 w-full text-sm">
                            <span>Strongly Disagree</span>
                            <span>Neutral</span>
                            <span>Strongly Agree</span>
                          </div>
                        </div>
                      )}
                      {/* Add more question types as needed */}
                    </li>
                  ))}
                </ul>
                <div className="w-full border-t border-gray-400 mt-6 mb-4"></div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="ml-3 flex justify-end text-gray-200 hover:underline py-2 px-4 text-sm font-medium"
                  >
                    Submit
                  </button>
                </div>
              </form>
            ) : (
              <p className="text-gray-400">No questions available for this survey.</p>
            )}
          </div>


        </div>
      </div>
    </div>

  );
}