import { URL } from ".";
import toast from 'react-hot-toast';

const createUser = async ({ token, name, email, title, team, organization_role, org_id }) => {
  try {
    const response = await fetch(`${URL}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        name,
        email,
        title,
        team,
        organization_role,
        organization: org_id
      })
    });
    toast.success('User created');
    return await response.json();
  } catch (error) {
    toast.error('Unable to create user');
  }
}

const readUser = async ({ token, user_id, include, start_date, end_date }) => {
  try {
    let url = `${URL}/users/${user_id}`;
    const params = new URLSearchParams();

    if (include) params.append('include', include);
    if (start_date) params.append('start_date', start_date);
    if (end_date) params.append('end_date', end_date);

    if (params.toString()) {
      url += `?${params.toString()}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Unable to read user:', error);
    throw error;
  }
}

const readUserTeam = async ({ token, user_id }) => {
  try {
    const response = await fetch(`${URL}/users/${user_id}/team`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    console.log('Unable to read user team', error);
  }
}

const readUserSurveys = async ({ token, user_id }) => {
  try {
    const response = await fetch(`${URL}/users/${user_id}/surveys`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    console.log('Unable to read user surveys', error);
  }
}
const updateUser = async ({ token, user_id, updates }) => {
  try {
    const response = await fetch(`${URL}/users/${user_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        updates
      })
    });
    toast.success('User updated');
    return await response.json();
  } catch (error) {
    toast.error('Unable to update user');
  }
}

const deleteUser = async ({ token, user_id }) => {
  try {
    const response = await fetch(`${URL}/users/${user_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    console.log('Unable to delete user', error);
  }
}



export { createUser, readUser, readUserTeam, readUserSurveys, updateUser, deleteUser }