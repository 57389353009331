import React, { useEffect, useState } from "react"
import { useUser } from "../contexts/user";
import { readOrgCommits, readOrgMembers } from '../api/organizations';
import Loading from '../components/Loading';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';


export default function LeaderboardPage() {
  const user = useUser();
  const navigate = useNavigate();
  const { org_id } = useParams()
  const [orgUsers, setOrgUsers] = useState([]);
  const [commitsByDate, setCommitsByDate] = useState([]);
  const [commits, setCommits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDays, setSelectedDays] = useState(30);


  const processCommitData = (fetchedCommits) => {
    const endDate = moment().endOf('day');
    const startDate = moment(endDate).subtract(selectedDays - 1, 'days').startOf('day');

    // Create an array of the selected number of days
    const selectedDaysArray = Array.from({ length: selectedDays }, (_, i) => {
      return moment(startDate).add(i, 'days').format('YYYY-MM-DD');
    });

    // Initialize commitsByDate with 0 commits for each day
    const commitCounts = selectedDaysArray.reduce((acc, date) => {
      acc[date] = 0;
      return acc;
    }, {});

    // Count commits for each day
    fetchedCommits.forEach(commit => {
      const commitDate = moment(commit.created_at).format('YYYY-MM-DD');
      if (commitDate in commitCounts) {
        commitCounts[commitDate]++;
      }
    });

    // Convert to array format for Recharts
    const newCommitsByDate = selectedDaysArray.map(date => ({
      date,
      label_date: moment(date).format('MM/DD'),
      commits: commitCounts[date]
    }));

    setCommitsByDate(newCommitsByDate);
  }

  useEffect(() => {
    const fetchOrgUsers = async () => {
      try {
        const { data } = await readOrgMembers({
          token: user.token,
          org_id: org_id
        });
        setOrgUsers([...data]);
      } catch (error) {
        console.error('Failed to fetch org users', error);
      }
    }
    const fetchOrgCommits = async () => {
      setLoading(true)
      try {
        const endDate = moment().endOf('day').format('YYYY-MM-DD');
        const startDate = moment().subtract(selectedDays - 1, 'days').startOf('day').format('YYYY-MM-DD');

        const { data } = await readOrgCommits({
          token: user.token,
          org_id: org_id,
          start_date: startDate,
          end_date: endDate
        });
        if (!!data) {
          setCommits([...data]);
          processCommitData([...data]);
        }
      } catch (error) {
        console.error('Failed to fetch org', error);
      }
      finally {
        setLoading(false)
      }
    };

    if (user) {
      fetchOrgCommits();
    }

  }, [user, selectedDays]);




  if (loading) {
    return <Loading />
  }

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Leaderboard</h2>
          <div className="flex items-center">
            {[7, 15, 30, 60, 90].map(days => (
              <button
                key={days}
                className={`px-3 m-1 rounded ${selectedDays === days ? 'bg-blue-500' : 'bg-gray-600'}`}
                onClick={() => setSelectedDays(days)}
              >
                {days}
              </button>
            ))}
          </div>
        </div>
        <div className="border border-gray-600 mb-4"></div>
        {!!commits && (
          <div className=" rounded-lg shadow-lg ">
            <div className="overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-700">
                    <th className="px-4 py-2 text-left">Rank</th>
                    <th className="px-4 py-2 text-left">Name</th>
                    <th className="px-4 py-2 text-right">Commits</th>
                  </tr>
                </thead>
                <tbody>
                  {commits
                    .reduce((acc, commit) => {
                      const existingUser = acc.find(user => user.user_id === commit?.user?._id);
                      if (!commit?.user) {
                        return acc;
                      }
                      if (existingUser) {
                        existingUser.commitCount += 1;
                      } else {
                        acc.push({
                          user_id: commit?.user?._id,
                          email: commit?.user?.email,
                          name: commit?.user?.name,
                          commitCount: 1
                        });
                      }
                      return acc;
                    }, [])
                    .sort((a, b) => b.commitCount - a.commitCount)
                    .map((user, index) => (
                      <tr key={user.id} className={index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-600'}>
                        <td className="px-4 py-2">{index + 1}</td>
                        <td className="px-4 py-2">{user.name}</td>
                        <td className="px-4 py-2 text-right">{user.commitCount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}