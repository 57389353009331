import { URL } from ".";
import toast from 'react-hot-toast';


const createSurvey = async ({ token, survey }) => {
  try {
    const response = await fetch(`${URL}/surveys`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        survey
      })
    });
    return await response.json();
  } catch (error) {
    console.log('wow', { error })
    toast.error('Unable to create survey');
  }
}
const saveSurveyResponses = async ({ token, survey_id, responses, complete, org_id, user_id }) => {
  try {
    const response = await fetch(
      `${URL}/organizations/${org_id}/profile/${user_id}/surveys/${survey_id}/responses`
      , {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          responses,
          complete
        })
      });
    return await response.json();
  } catch (error) {
    console.log(error)
    toast.error('Unable to save responses');
  }
}

const readSurvey = async ({ token, survey_id }) => {
  try {
    const response = await fetch(`${URL}/surveys/${survey_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read survey');
  }
}

const readActiveSurveys = async ({ token, survey_id }) => {
  try {
    return;
    const response = await fetch(`${URL}/surveys/${survey_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read survey');
  }
}
const readUserSurvey = async ({ token, user_id }) => {
  try {
    const response = await fetch(`${URL}/user/${user_id}/surveys`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read survey');
  }
}

const readOrgSurveys = async ({ token, org_id }) => {
  try {
    const response = await fetch(`${URL}/organizations/${org_id}/surveys`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read org surveys');
  }
}


export { readOrgSurveys, createSurvey, readSurvey, readUserSurvey, readActiveSurveys, saveSurveyResponses }