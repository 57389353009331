import { URL } from ".";
import toast from 'react-hot-toast';

const readOrg = async ({ token, org_id, include, start_date, end_date }) => {
  try {
    let url = `${URL}/organizations/${org_id}`;
    if (include) {
      url += `?include=${include}`;
    }

    if (start_date && end_date) {
      url += `?start_date=${start_date}&end_date=${end_date}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read org');
  }
}

const readOrgTeams = async ({ token, org_id }) => {
  try {
    const response = await fetch(`${URL}/organizations/${org_id}/teams`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read org teams');
  }
}

const readOrgMembers = async ({ token, org_id }) => {
  try {
    const response = await fetch(`${URL}/organizations/${org_id}/members`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read org members');
  }
}

const readOrgCommits = async ({ token, org_id, start_date, end_date }) => {
  try {

    let url = `${URL}/organizations/${org_id}/commits`;
    const params = new URLSearchParams();

    if (start_date) params.append('start_date', start_date);
    if (end_date) params.append('end_date', end_date);

    if (params.toString()) {
      url += `?${params.toString()}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read org commits');
  }
}

export { readOrgTeams, readOrg, readOrgCommits, readOrgMembers }